#invoice_report,
#load_receipt_report {
  /* font-family: Arial, sans-serif !important; */
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 600;


  /* width: 50vw ; */
}

#img-logo {
  width: 96px;
}

#invoice_report #logo,
#load_receipt_report #logo {
  width: 96px;
}

#invoice_report .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 18px !important;
  color: #000000 !important;
  padding: 5px !important;
}

#invoice_report .table td,
.table th {
  padding: 0.2rem !important;
}

/* LR Report */

/* #load_receipt_report .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 18px !important;
  color: #000000 !important;
  padding: 5px !important;
} */

#load_receipt_report .table td,
.table th {
  padding: 0.3rem !important;
}

.cancelled-watermark {
  background-image: url("cancelled-watermark.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}

.border-dark {
  border: 1px solid #303030;
}
